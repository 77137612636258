// Lepton X Theme Styling

.lpx-content {
  min-height: initial;
}

.lpx-topbar-container {
  position: initial;
}
.lpx-topbar {
  position: initial;
}

.lpx-content-container .lpx-content {
  padding-top: 0;
}

.lpx-topbar-container .lpx-topbar {
  border: none;
  padding: 1em 2em 0em;
}

.lpx-breadcrumb-container {
  height: auto;
}

@media only screen and (max-width: 767px) {
  .lpx-content-container .lpx-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lpx-topbar-container {
    position: initial;
  }

  .lpx-topbar-container .lpx-topbar {
    padding: 1rem 1rem 0em;
  }
}

// Bootstrap 4 Styling

@media only screen and (max-width: 767px) {
  .card .card-body {
    padding: 1rem;
  }
}

.p-button.p-button-xs {
  font-size: 0.75rem; /* smaller font size than p-button-sm */
  padding: 0.4rem 0.8rem; /* smaller padding to reduce overall button size */
  width: 2rem !important;
}

.p-table-footer-right-padding-none .p-paginator {
  padding-right: 0 !important;
}
