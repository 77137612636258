:host ::ng-deep .p-selectbutton .p-button {
    border-radius: 8px;
    /* Rounder corners for select buttons */
    background-color: #f5f5f5;
    /* Change background color */
    color: #333;
    /* Change text color */
}

:host ::ng-deep .p-selectbutton .p-button.p-highlight {
    background-color: #007bff !important;
    /* Change selected button color */
    color: #fff !important;
    /* Change selected button text color */
}