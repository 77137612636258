app-activity-log {
  display: flex;
  flex-direction: column;
  height: 50%;

  .ticket-log-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;

    border-width: 1px;
    border-style: solid;
    border-color: #ccced1;
    padding: 0.5rem;
    background-color: #fbfbfb;
    
    height: 100%;
    overflow-y: auto;
  }

  .log-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .activity-item {
      max-width: 70%;
    }
    
    .timestamp {
      font-size: 0.75rem;
      color: #b5b5b5;
      text-align: end;
    }
  }
}