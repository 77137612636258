.p-breadcrumb {
  background: transparent !important;
  display: flex;
  // justify-content: center;
}
// :host ::ng-deep .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
// 	color: #fff !important;
// }
// :host ::ng-deep .p-breadcrumb ul li.p-breadcrumb-chevron {
// 	color: #fff !important;
// }

// :host ::ng-deep .p-breadcrumb ul li:last-child .p-menuitem-text {
// 	color: #fff !important;
// 	font-weight: 600;
// }
// :host ::ng-deep .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
// 	color: #fff !important;
// }
// .p-breadcrumb ul li .p-menuitem-link:focus {
// 	box-shadow: 0 0 0 0.2rem #0b2014 !important;
// }

.left-arrow-button.p-button {
  border-radius: 4px 0 0 4px;
}

.right-arrow-button.p-button {
  border-radius: 0 4px 4px 0;
}

.p-card .p-card-content,
.p-card .p-card-footer {
  padding: 0;
}

.p-dialog-header h3 {
  margin-bottom: 0;
}

.p-card .p-card-body {
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.p-card.p-card {
  padding: 1rem 0rem;
}

.p-inputtext.p-chips-multiple-container {
  width: 100%;
}

.inactive-item {
  color: #969595;
}

svg.p-icon-spin {
  vertical-align: text-top;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 100;
  max-height: 13rem;
  max-width: 45.5rem;
  overflow-y: auto;
}

// lpx-settings {
//   display: none;
// }

.dropdown-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.item-id {
  font-weight: bold;
}

// .form-number {
//   display: block;
//   .p-inputnumber {
//     width: 100%;
//     .p-inputnumber-input {
//       background-color: #f0f4f7;
//       width: 100%;
//       padding: 0.675rem 1.25rem;
//       font-size: 0.875rem;
//       font-weight: 500;
//       line-height: 1.5;
//       background-clip: padding-box;
//       border: 1px solid #e8eef3;
//       appearance: none;
//       box-shadow: none;
//       border-color: #e8eef3;
//       color: #062a44;
//     }
//   }
// }

.numbers-flex-container {
  flex-direction: row;
}

.trash-btn > .p-button,
.cancel-btn > .p-button {
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
}

.add-btn > .p-button {
  background-color: #28a745;
  border-color: #28a745;
  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
}

.move-btn > .p-button {
  background-color: #f1f5f9;
  border-color: #f1f5f9;
  .p-button-icon {
    color: #062a44;
  }
  &:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
}

.red-strikethrough {
  text-decoration: line-through;
  color: red;
}

.p-confirm-popup:before {
  border-width: 0 !important;
}

.p-confirm-popup:after {
  border-width: 0 !important;
}

.ck-editor__editable_inline {
  min-height: 14rem;
}

.surface-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 0;
}

.surface-hover {
  background-color: #f6f9fc !important;
}

.ground-card {
  background-color: #f7f9fc;
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 0;
}

.link-btn {
  button {
    padding: 0.25em 0.5em;
  }
}
