app-estimate-tree-view {

  td.button-cell {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 0.5rem;

    margin-bottom: 1rem;

    font-size: 0.875rem;
  }
}