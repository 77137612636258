.p-accordion .p-accordion-header .p-accordion-header-link {
  border-color: lightgray;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  border-color: lightgray;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border-color: lightgray;
  border-bottom: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: lightgray;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
