app-message-box {
  height: 100%;

  p {
    margin-bottom: 0;
  }

  .spinner, .empty-messages {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .timestamp {
    font-size: 0.75rem;
    color: #b5b5b5;
  }

  .internal-label {
    font-style: italic;
    font-size: 0.75rem;
    color: #b5b5b5;
  }

  .reply-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 1.5rem;
    width: 100%;

    .worker-reply, .customer-reply, .internal-reply {
      display: flex;
      flex-direction: column;
      
      .reply-content {
        // border-radius: 0.5rem;
        border: 1px solid rgb(218, 218, 218);
        padding: 0.75rem;
        max-width: 80%;
      }
    }
  }
}