app-ec-calculator {
  .line-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  div.card-body {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}