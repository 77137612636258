app-support-ticket-edit {

  $border-color: #ccced1;
  .ticket-container {
    display: flex;
    // column-gap: 0.75rem;
    padding: 0;
    background-color: transparent;
  }

  .customer-reply {
    align-items: start;
    .reply-content {
      background-color: white;
      border-radius: 0.5rem 0.5rem 0.5rem 0;
    }
  }

  .worker-reply {
    align-items: end;
    .reply-content {
      background-color: rgb(229, 235, 255);
      border-radius: 0.5rem 0.5rem 0 0.5rem;
    }
  }

  .internal-reply {
    align-items: end;
    .reply-content {
      background-color: #ededed;
      border-radius: 0.5rem 0.5rem 0 0.5rem;
    }
  }

  .ticket-messages {
    height: calc(100vh - 146px);
    display: flex;
    flex-direction: column;
    width: 75%;

    border-color: $border-color;
    border-style: solid;
    border-radius: 0.5rem 0 0 0.5rem;
    border-width: 1px 0 1px 0;
    overflow: clip;

    .ticket-header {
      background-color: white;
      padding: 1.25rem;
      border-width: 0 1px 1px 1px;
      border-color: $border-color;
      border-style: solid;

      display: flex;
      align-items: center;

      h3 {
        margin: 0;
      }
    }
  }

  .message-box-container {
    height: 100%;
    background-color: #fbfbfb;
    padding: 1.25rem;
    border-width: 0 1px 0 1px;
    border-color: $border-color;
    border-style: solid;

    overflow-y: auto;
  }

  .ticket-details {
    display: flex;
    flex-direction: column;

    height: calc(100vh - 146px);
    background-color: white;
    padding: 1.25rem;
    width: 25%;

    border-color: $border-color;
    border-style: solid;
    border-radius: 0 0.5rem 0.5rem 0;
    border-width: 1px 1px 1px 0;
    overflow-y: auto;

    .ticket-details-container {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
      height: 50%;
      overflow-y: auto;
    }

    h4 {
      margin: 0;
    }

    .details-label {
      font-size: 1rem;
      font-weight: 500;
    }

    .detail {
      font-size: 1rem;
    }

    .details {
      display: flex;
      flex-direction: column;
    }

    .detail-row {
      display: flex;
      column-gap: 0.5rem;
      .details {
        width: 50%;
      }
    }

    .p-dropdown {
      width: 100%;
    }
  }

  .reply-entry-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;

    .reply-button-container {
      width: 100%;
      background-color: white;
      border-width: 0 1px 0 1px;
      border-color: $border-color;
      border-style: solid;
      padding: 1rem;

      display: flex;
      justify-content: end;
      align-items: center;
      column-gap: 0.5rem;
    }

    .ck-editor__editable_inline {
      min-height: 14rem;
    }
  }

  .attachments-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

}