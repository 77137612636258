.breadcrumb {
  background-color: var(--lpx-content-bg);
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 1em !important;
  border: 0 !important;
}

.breadcrumb-item {
  font-size: 1.25rem; /* Adjust the text size here */
}

.breadcrumb-item a {
  color: inherit;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: #5893cf;
  text-decoration: underline;
}

.breadcrumb-item.active a {
  font-weight: bold;
  color: #5893cf; /* Or any color you'd like for the active breadcrumb */
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    padding: 0.5rem 0.75rem;
  }

  .breadcrumb-item {
    font-size: 1rem; /* Adjust the text size here */
  }
}
