app-view-estimate {
  .card-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .p-knob-text {
    font-size: 1rem;
    font-weight: 500;
  }
}